import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Media from 'react-media'

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <strong>Forty</strong> <span>by HTML5 UP</span>
    </Link>
    <nav>
      <Media query="(max-width: 850px)">
        <a
          className="menu-link"
          onClick={props.onToggleMenu}
          href="javascript:;"
        >
          Menu
        </a>
      </Media>
    </nav>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
